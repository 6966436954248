<template lang="pug">
  .portal
    .header
      .title.md-title {{ username }}
      .logout
        md-button(class="md-raised" v-on:click="logout()")  Logout

    img(v-bind:src="logoUrl" class="center logo")

    .table-container(v-if="assignedForms.length > 0")
      table.table
        tbody 
          tr
            th Form Name
            th Date Assigned
            th 

          tr(
            v-for="form in assignedForms" :key="form.formID",
            v-if="assignedForms.length"
          ) 
            td {{ form.name }}
            td {{ form.dateTimeAssigned | moment("MMM Do YYYY, h:mm:ss a") }}
            td 
              md-button(class="md-raised" :disabled="openFormID === form.formID" v-on:click="complete(form)") Complete
    h2(style="padding: 50px;" v-if="assignedForms.length === 0") You are all set! If you believe that you should have forms to fill out please reach out to your dental office.
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'OpPortal',
  data() {
    return {
      forms: []
    };
  },
  methods: {
    complete({ sheetDefNum, assignmentID, formID }) {
      this.$router.push({ path: `/${ this.partialGroupID }/complete/${ formID }` })
    },
    logout() {
      this.$store.commit('session/logout');
    }
  },
  computed: {
    ...mapGetters('forms', [
      'assignedForms',
      'openFormID'
    ]),
    ...mapGetters('session', [
      'username'
    ]),
    ...mapGetters('group', [
      'logoUrl',
      'partialGroupID'
    ])
  },
  watch:{
    $route (to, from) {
        this.$store.dispatch('forms/fetchFormDefinition', this.$route.params);
    }
  }, 
  async created() {
    await this.$store.dispatch('forms/getForms');
    this.$store.dispatch('group/getLogoUrl');

    const { formID } = this.$route.params;
    if (formID) {
      await this.$store.dispatch('forms/fetchFormDefinition', { formID });
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 90%;
  margin: auto;
  padding: 30px;
}
.maincard {
  padding: 25px 0;
}
.md-field,
.para {
  margin: 4px 25px 17px;
  width: auto;
}
.para {
  white-space: pre;
  text-align: left;
  display: grid;
  white-space: break-spaces;
}
.header {
  background-color: #464570;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 25px 50px;
  .title {
    height: 25px;
    margin: auto 0;
  }
}
.logo {
  width: 90%;
  max-width: 750px;
  padding: 35px 100px 0px;
}
</style>
