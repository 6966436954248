import { computeModeSetting } from "vee-validate/dist/types/components/common";

import moment from 'moment';
export default function compute(value) {
    const newdate = moment(value, 'MMDDYYYY');
    const today  = moment();

    const invalidYear = year => year < 100;
    let year = newdate.year();
    
    if (invalidYear(year)) {
      const possibleYear1 = 1900 + year;
      const possibleYear2 = 2000 + year;
      year = possibleYear2 > today.year() ? possibleYear1 : possibleYear2
    }
    return newdate.set('year', year).format('MM/DD/YYYY');
}