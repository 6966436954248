<template lang="pug">
  .login-container
      md-card(class="md-layout-item md-size-50 md-small-size-100" style="margin: auto;")
        ValidationObserver(v-slot="{ handleSubmit , invalid }")
          form(@submit.prevent="handleSubmit(login)" style="padding: 70px;")
            md-card-header
              .md-title Sign In
      
            md-card-content(ref="loginForm")
              
              p Please enter the information as it was provided to the dental office
              md-field
                label First Name
                md-input(v-model="firstName" required)

              md-field
                label Last Name
                md-input(v-model="lastName" required)

              div(@focusout="dateChange")
                validation-provider(rules="required" v-slot="{ errors }")
                  md-datepicker(v-model="dob" :md-open-on-focus="false" :md-immediately="true" :md-disabled-dates="disabledDates")
                    label Date of Birth *
                    span.md-helper-text(style="color:#CCCCCC;") MM/DD/YYYY
                  p(style="color: red;") {{ errors[0] }}

              #html_element.captcha
              //- v-if="!isRobot"
              md-progress-spinner(md-mode="indeterminate" v-if="isSubmitting")
              span(v-else)
                md-button(class="md-raised md-primary" value="Submit" type="submit" :md-open-on-focus="false" v-if="!isRobot") Login

              p(v-if="failedLogin" style="color:red;") Failed login attempt. Please reach out to your dental office to verify that your information was entered correctly.
    
</template>
<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import * as moment from 'moment';
import autoComputeYear from '../yearComputeHelper';
extend('required', { 
  ...required,
  message: 'This field is required'
});

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});
export default {
  name: 'Login',
  components: {
    ValidationProvider, ValidationObserver
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      dob: '',
      failedLogin: false,
      isSubmitting: false,
      disabledDates: date => {
        const thedate = moment(date);
        return thedate.isBefore(moment('01/01/1900')) || thedate.isAfter(moment());
      }
    }
  },
  computed: {
    ...mapGetters('session', [
      'isRobot'
    ])
  },
  methods: {
    async login() {
      console.log('@ the login method')
      this.isSubmitting = true;
      try {
        await this.$store.dispatch('session/login', {  firstName: this.firstName, lastName: this.lastName, password: this.dob });
      } catch (e) {
        this.failedLogin = true
      }
      this.isSubmitting = false;
    },
    dateChange(e) {
      this.$set(this, 'dob', autoComputeYear(e.target.value));
    }
  },
  async mounted() {
    await this.$nextTick();
  },
}
</script>
<style lang="scss">
.login-container {
  background: white;
}
.md-card-content {
  max-width: 500px;
  margin: auto;
}
.captcha{
  margin: auto;
  width: fit-content;
}
</style>