import Vue from 'vue';
import * as toastr from 'toastr';
import { gzip } from 'pako';
import moment from 'moment';
import { clone } from 'lodash'; 
import jsPDF from 'jspdf';

const state = () => ({
    assignedForms: [],
    formDefinitions: {},
    openFormID: '',
    isPreviewMode: false,
    apiKey: '8QJHAHG-5ZWM1Y1-KPR1MPH-2250FH0',
    submitterIP: ''
});

const getters = {
    assignedForms: (state, getters, rootState) => {
        return state.assignedForms.filter(f => !f.submissionDateTime);
    },
    isPreviewMode: state => state.isPreviewMode,
    submitterIP: state => state.submitterIP,
    formDefinitions: state => state.formDefinitions,
    openFormID: state => state.openFormID,

    getFormDefinitionByFormID: (state) => (formID) => {
        return state.formDefinitions[formID] || {};
    },
}

const actions = {
    async submit({ state, commit, dispatch }, { formHTML, formID, fieldGroups }): Promise<void> {
        const doc = new jsPDF("p", "px", [500, 707]);
        return new Promise((resolve, reject) => {
            doc.html(formHTML, {
                callback: async (doc) => {
                    var pdf = doc.output('datauristring');

                    var formData = new FormData();

                    formData.append("file", gzip(pdf));

                    const fg = fieldGroups.map(f => {
                        if (f.length === 1) {
                            return f;
                        } else {
                            const copy = { ...f };
                            var fieldValue = f[0].FieldValue
                            for (var i in f) {
                                console.log(i, '-', fieldValue, '-', f[i].RadioButtonValue, '-', f[i].UiLabelMobileRadioButton, '-', copy[i], f[i])

                                if (copy[i].FieldValue !== undefined && fieldValue) {
                                    if (['Y', 'N'].indexOf(copy[i].RadioButtonValue) !== -1) {
                                        fieldValue = fieldValue[0];
                                    }

                                    if (fieldValue === f[i].RadioButtonValue) {
                                        copy[i].FieldValue = 'X'
                                    } else if (fieldValue === f[i].UiLabelMobileRadioButton) {
                                        copy[i].FieldValue = 'X'
                                    } else {
                                        copy[i].FieldValue = '';
                                    }
                                }
                            }
                            return copy;
                        }
                    });

                    formData.append("formData", gzip(JSON.stringify(fg)));
                    formData.append("formID", formID);
                    formData.append("assignmentID", (state.assignedForms.find(f => f.formID === formID)).assignmentID);
                    formData.append("assigneeID", state.assigneeID)

                    var successfullySubmitted = true;
                    try {
                        await dispatch('http/post',
                            { path: '/api/portal/submission', body: formData }, { root: true });
                    } catch (e) {
                        successfullySubmitted = false;
                        toastr.error('something went wrong. Please try submitting the form again.');
                    }
                    if (successfullySubmitted) {
                        toastr.success('Form Submitted');
                        await commit('setFormSubmitted', { formID });
                        await commit('clearForm', { formID });
                        await commit('getForms');
                    }

                    resolve();
                }
            });
        })
    },
    async getForms({ commit, rootState, dispatch }) {
        const { groupID, assigneeID } = rootState.session.user;
        if (!groupID || !assigneeID) {
            console.log('getForms - no user in session');
            dispatch('session/refresh', {}, { root: true });
            return;
        }
        const data = await dispatch('http/get', { path: `/api/portal/${ groupID }/assignee/${ assigneeID }` }, { root: true })
        console.log('got the forms', data, assigneeID)
        if (data) {
            commit('addAvailableForms', { forms: data, assigneeID })
        }
    },
    async fetchFormDefinition({ commit, state, dispatch }, { formID }) {
        if (!state.assignedForms.find(f => f.formID === formID)) {
            return;
        }
        commit('setOpenFormID', { formID })
        const data = await dispatch('http/get', { path: `/api/portal/complete/${ formID }` }, { root: true })

        dispatch('setupFormDefinition', { formID, data })
    },

    async fetchFormDefinitionForPreview({ commit, state, dispatch }, { groupID, sheetDefNum }) {
        console.log('fetchFormDefinitionForPreview', groupID, sheetDefNum)
        const data = await dispatch('http/get', {
            path: `/api/portal/${ groupID }/preview/${ sheetDefNum }`,
            headers: { apiKey: state.apiKey }
        }, { root: true })
        commit('setOpenFormID', { formID: data.formID })
        dispatch('setupFormDefinition', { formID: data.formID, data })
    },

    async fetchSubmitterIP({ commit, state, dispatch }) {
        const ip = await dispatch('http/get', {
            path: `/api/portal/myip`
        }, { root: true });

        commit('resolveSubmitterIP', { ip })
    },
    setupFormDefinition({ commit, rootState }, { formID, data }) {
        const firstName = rootState.session.firstName;
        const lastName = rootState.session.lastName;
        const dob = rootState.session.dob
        commit('addFormDefinition', { formID, data, firstName, lastName, dob })
    }
}

const mutations = {
    resolveSubmitterIP(state, { ip }) {
        state.submitterIP = ip;
    },
    addAvailableForms(state, { forms, assigneeID }) {
        state.assignedForms.length = 0;
        state.assignedForms.push(...forms.filter(f => f.assigneeID === assigneeID && !f.submissionDateTime));
        state.assigneeID = assigneeID;
    },

    addFormDefinition(state, { formID, data, firstName = '', lastName = '', dob = '' }) {
        const formName = data.name;

        const sortedFields = data.fields.sort(
            (a, b) => a.TabOrderMobile - b.TabOrderMobile
        );

        // -----------------------------------------------------------------------
        // we group these because we need away to group radio buttons and ignore non-mobile view
        const groupedFields = sortedFields.reduce((agg, next) => {
            if (next.TabOrderMobile === 0) {
                // not included in mobile view => ignore
                return agg;
            }
            if (next.FieldType === 2 || next.FieldType === 9) {
                if (next.FieldValue === 'Date: [dateToday]') {
                    next.FieldValue = `Date: ${ moment().format('MMM DD YYYY') }`
                }

                agg['uniq' + Math.random()] = [next];
                return agg;
            }
            if (agg[next.UiLabelMobile + next.FieldName]) {
                if (next.FieldType === 8) {
                    agg[next.UiLabelMobile + next.FieldName].push(next);
                } else if (next.FieldName !== '') {
                    agg[next.UiLabelMobile + next.FieldName + Math.random()] = [next];
                }
            } else {
                if (next.FieldName == 'FName') {
                    next.FieldValue = firstName;
                }
                if (next.FieldName == 'LName') {
                    next.FieldValue = lastName;
                }
                if (next.FieldName == 'Birthdate') {
                    next.FieldValue = dob;
                }
                agg[next.UiLabelMobile + next.FieldName] = [next];
            }
            return agg;
        }, {});

        const fieldGroups = Object.values(groupedFields).map((item: any, index) => {
            item.id = index;
            return item;
        });
        Vue.set(state.formDefinitions, formID, { fieldGroups, formName })
    },

    setFormSubmitted(state, { formID }) {
        const i = state.assignedForms.findIndex(f => f.formID === formID);
        Vue.set(state.assignedForms, i, { ...state.assignedForms[i], submissionDateTime: new Date() });
    },

    clearForm(state, { formID }) {
        Vue.set(state.formDefinitions, formID, null);
    },

    setOpenFormID(state, { formID }) {
        state.openFormID = formID;
    },

    setPreviewMode(state, { isOn }) {
        console.log('preview mode:', isOn)
        state.isPreviewMode = isOn;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}