import axios from 'axios';

const state = () => ({
    http: axios,
    headers: {
        Authorization: localStorage.getItem('token') || ''
    },
    attempts: 0
})

const getters = {
    isLoggedIn: state => !!state.token,
}

const actions = {
    async post({ state, commit, dispatch }, { path, body }) {
        try {
            const { data } = await axios.post(path, body, { headers: state.headers });
            return data;
        } catch (e) {
            if (e.response.status === 401) {
                try {
                    await dispatch('session/refresh', {}, { root: true });
                    const { data } = await axios.post(path, body, { headers: state.headers });
                    return data;

                } catch (e) {
                    commit('session/logout', {}, { root: true });
                    throw e;
                }
            } else {
                throw e;
            }
        }
    },
    async get({ state, commit, dispatch }, { path, headers }) {
        try {
            const { data } = await axios.get(path, { headers: { ...state.headers, ...headers } });
            return data;
        } catch (e) {
            if (e.response.status === 401) {
                try {
                    await dispatch('session/refresh', {}, { root: true });
                    const { data } = await axios.get(path, { headers: state.headers });
                    return data;

                } catch (e) {
                    commit('session/logout', {}, { root: true })
                }
            }
        }
    },
}

const mutations = {
    async set_authentication_header(state, { token }) {
        state.headers['Authorization'] = 'Bearer ' + token
        await localStorage.setItem('token', state.headers['Authorization']);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}