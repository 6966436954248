<template lang="pug">
  div
    md-content.mask(v-if="isSubmitting")
      md-content(style="margin: auto; width: 200px")
        label Submitting form...
        md-progress-spinner(md-mode="indeterminate") 

    div(v-if="formDefinition.formName")
      #app.form
        md-card.md-layout-item.md-size-50.md-small-size-100(style="margin: auto; max-width: none;")
          ValidationObserver(v-slot="{ handleSubmit, invalid }")
            form(@submit.prevent="handleSubmit(submitForm)" ref="formHtml", class="formContainer")
              md-toolbar(:md-elevation="1")
                span.md-title {{ formDefinition.formName }}

              md-card-content.maincard
                div(v-for="(fields, index) in formDefinition.fieldGroups" :key="fields.id")
                  md-field(v-if="fields.length === 1 && [2, 9, 15].indexOf(fields[0].FieldType) === -1 && fields[0].UiLabelMobile.toLowerCase().indexOf('date') === -1" 
                    :class="invalidClass")
                    label {{ fields[0].UiLabelMobile }}
                    md-input(v-model="fields[0].FieldValue" :value="fields[0].FieldValue"
                      :required="!!fields[0].IsRequired" v-bind:ref="fields[0].UiLabelMobile" @change="changeHandler")
                  
                  .thinContainer(v-if="fields.length === 1 && [2, 9, 15].indexOf(fields[0].FieldType) === -1 && fields[0].UiLabelMobile.toLowerCase().indexOf('date') !== -1")
                    .thinContainer(v-if="!!fields[0].IsRequired" @focusout="datechangeHandler($event, index)")
                      validation-provider(rules="required" v-slot="{ errors }")
                        md-datepicker(v-model="fields[0].FieldValue"
                          :md-open-on-focus="false" :md-immediately="true"  v-show="!submitted"
                          )
                          label {{ fields[0].UiLabelMobile }} *
                          span.md-helper-text(style="color:#CCCCCC;") MM/DD/YYYY
                        p(style="color: red;") {{ errors[0] }}
             
                      md-field(v-show="submitted")
                        label {{ fields[0].UiLabelMobile }}
                        md-input(v-model="fields[0].FieldValue")

                    .thinContainer(v-else @focusout="datechangeHandler($event, index)")
                      md-datepicker(v-model="fields[0].FieldValue"
                        :md-open-on-focus="false" :md-immediately="true"
                        :class={ leftAlign:  submitted } v-show="!submitted"
                        )
                        label {{ fields[0].UiLabelMobile }}
                        span.md-helper-text(style="color:#CCCCCC;") MM/DD/YYYY
                      
                      md-field(v-show="submitted")
                        label {{ fields[0].UiLabelMobile }}
                        md-input(v-model="fields[0].FieldValue")

                  .header(v-if="fields.length === 1 && fields[0].FieldType === 15")
                    md-card-header
                      .md-title {{ fields[0].UiLabelMobile }}

                  div(v-if="fields.length === 1 && fields[0].FieldType === 2")
                    md-content.para {{ fields[0].FieldValue }}

                    

                  div(v-if="fields.length === 1 && fields[0].FieldType === 9" style="page-break-inside: avoid;")
                    img(v-bind:src="fields[0].FieldValue" v-if="fields[0].FieldValue")
                    div(v-else)
                      VueSignaturePad(style="border: 1px solid #DDDDDD; margin: auto;"
                          width="450px"
                          height="300px"
                          v-bind:ref="'signaturePad_' + index")

                      input.hiddenInput(v-model="fields[0].FieldValue" required tabindex="-1")
                      div
                        md-button.md-raised(@click="saveSignature(index, fields)") Save Signature
                        md-button.md-raised(@click="undoSignature(index, fields)") Undo Signature

                    label.center(style="display: block;") {{fields[0].UiLabelMobile}}

                  .center(v-if="fields.length > 1 && fields[0].UiLabelMobile")
                    validation-provider(:rules="`${fields[0].IsRequired ? 'required' : ''}`" v-slot="{ errors }")
                      .center
                        label {{ fields[0].UiLabelMobile }} {{ fields[0].IsRequired ? '*' : '' }}
                      .center
                        div(v-for="field in fields" :key="field.RadioButtonValue + Math.random()")
                            md-radio(v-if="!fields[0].UiLabelMobileRadioButton"
                              v-model="fields[0].FieldValue" 
                              :name="field.UiLabelMobile" 
                              type="radio" 
                              ref="radio"
                              @change="changeHandler"
                              :value="field.RadioButtonValue") {{ field.RadioButtonValue }}
                            md-radio(v-else
                              v-model="fields[0].FieldValue" 
                              :name="field.UiLabelMobile" 
                              ref="radio"
                              type="radio" 
                              @change="changeHandler"
                              :value="field.UiLabelMobileRadioButton") {{ field.UiLabelMobileRadioButton }}
                      div(v-if="errors.length" style="color:red;") {{ errors[0] }}
          

                    
                md-button.md-raised.md-primary(v-show="!isPreviewMode && !isSubmitting" value="Submit" type="submit") Submit
                .center(v-if="submitterIP !== ''") Submitter IP: {{ submitterIP }}
                .center(v-if="submittedOnTimeStamp !== ''") Submitted On: {{ submittedOnTimeStamp }}
</template>

<script>

import { mapGetters } from 'vuex'
import { get } from 'lodash';
import { sha1 } from 'object-hash';

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import autoComputeYear from '../yearComputeHelper';

extend('required', { 
  ...required,
  message: 'This field is required'
});

export default {
  name: 'OpForm',
  components: {
    ValidationProvider, ValidationObserver
  },
  data() {
    return {
      formName: '',
      formID: '',
      isSubmitting: false,
      originalFormHash: '',
      formDefinitionlocal: {},
      submittedOnTimeStamp: '',
      submitted: false
    };
  },
  methods: {
    undoSignature(index, field) {
      this.$refs['signaturePad_' + index][0].undoSignature();
      this.$set(this.formDefinition.fieldGroups, index, [{ ...this.formDefinition.fieldGroups[index][0], FieldValue: '' }]);
    },
    async saveSignature(index, field) {      
      const { isEmpty, data } = this.$refs['signaturePad_' + index][0].saveSignature();
      if (!isEmpty) {
        this.$set(this.formDefinition.fieldGroups, index, [{ ...this.formDefinition.fieldGroups[index][0], FieldValue: data }]);
      }
    },
    async invalidateSignatures() {
      this.formDefinition.fieldGroups.forEach((f, index) => {
        if (f[0] && f[0].FieldType === 9) {
          this.$set(this.formDefinition.fieldGroups, index, [{ ...this.formDefinition.fieldGroups[index][0], FieldValue: '' }]);
        }
      });
      for (var key in this.$refs) {
        const ref = get(this.$refs, key + '.0');
        if (ref && ref.undoSignature) {
          ref.undoSignature();
        }
      }
    },
    async submitForm() {
      for (var key in this.$refs) {
        const ref = get(this.$refs, key + '.0')
        if (ref) {
          if (ref.isEmpty && ref.isEmpty()) {
            console.log('missing signature');
            return;
          }
          if (ref.required && ref.value === '') {
            console.log('missing required field', ref.name);  
            return;
          }
        }
      }
      this.submitted = true;
      this.isSubmitting = true;
      this.submittedOnTimeStamp = new Date();
      await this.$nextTick();

      await this.$store.dispatch('forms/submit',  { 
        formHTML: this.$refs.formHtml, 
        formID: this.openFormID, 
        fieldGroups: this.formDefinition.fieldGroups 
      });
      this.isSubmitting = false;
      window.onbeforeunload = null;
    },
    datechangeHandler(e, index) {
      const newVal = autoComputeYear(e.target.value);
      const oldVal = this.formDefinition.fieldGroups[index][0].FieldValue;
      if (newVal !== oldVal) {
        this.$set(this.formDefinition.fieldGroups, index, [{ ...this.formDefinition.fieldGroups[index][0], FieldValue: newVal }]);
        this.changeHandler();
      }
    },
    changeHandler() {
      if (sha1(this.formDefinition) !== this.originalFormHash) {
        this.invalidateSignatures();
        window.onbeforeunload = function() {
            return "Changes detected";
        }
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  async updated() {
    if (this.setRequired) {
      return
    }
    await this.$nextTick();
    for (let key in this.$refs) {
      const ref = get(this.$refs, key + '.0');
      if (get(ref,'$attrs.required')) {
        ref.$refs.input.required = true;
      }
    }
    this.setRequired = this.$refs.length;
  },
  computed: {
    invalidClass() {
      return {
        'md-invalid': this.hasMessages,
      };
    },
    ...mapGetters('forms', [
      'formDefinitions',
      'openFormID',
      'isPreviewMode',
      'submitterIP'
    ]),
    formDefinition() {
      const formDefinition = this.$store.getters['forms/getFormDefinitionByFormID'](this.openFormID);

      if (!this.originalFormHash && Object.keys(formDefinition).length !== 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.originalFormHash = sha1(formDefinition);
      }
      this.submittedOnTimeStamp = '';

      return formDefinition
    },
  },
  async created() {    
    if (this.isPreviewMode) {
      this.$store.dispatch('forms/fetchFormDefinitionForPreview', this.$route.query)
    }
    this.$store.dispatch('forms/fetchSubmitterIP')
  }
};
</script>

<style scoped lang="scss">
.mask {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 10;
  display: flex;
  label {
    padding: 20px;
    display: block;
  }
}
.maincard {
  padding: 25px 0;
  label {
    font-size: small;
  }
}
.mainForm {
  background: white !important;
  padding: 0 !important;
}
.md-field,
.para {
  margin: 4px 25px 25px;
  width: auto;
}
.para {
  white-space: pre;
  text-align: left;
  display: grid;
  white-space: break-spaces;
}
.header {
  padding: 25px 0;
}
.form {
  padding-bottom: 50px;
  margin: auto;
  width: 100%;
  max-width: 500px;
  .formContainer {
    -webkit-print-color-adjust: exact;
  }
}
.hiddenInput {
  border: none;
  width: 0;
  height: 0;
}
.center {
    width: fit-content;
    margin: auto;
    max-width: 90%;
    position: relative;
    display: flex;
}
.thinContainer {
  display: contents;
}
.md-datepicker {
  margin-bottom: 50px;
}




</style>
