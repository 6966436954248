const state = () => ({
    logoUrl: '',
    partialGroupID: ''
})

const getters = {
    logoUrl: state => state.logoUrl,
    partialGroupID: state => state.partialGroupID
}

const actions = {
    async getLogoUrl({ commit, rootState, dispatch }) {
        try {
            const { groupID, assigneeID } = rootState.session.user;
            if (!groupID || !assigneeID) {
                return;
            }
            const data = await dispatch('http/get', { path: `/api/portal/${ groupID }/logoUrl` }, { root: true });
            if (data) {
                commit('setLogoUrl', data);
            }
        } catch (e) {
            commit('auth_error');
        }
    },
}

const mutations = {
    setLogoUrl(state, data) {
        state.logoUrl = data;
    },
    setPartialGroupID(state, { partialGroupID }) {
        state.partialGroupID = partialGroupID;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}