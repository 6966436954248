import Vue from 'vue';
import App from './App.vue';

import env from './environments/environment'

Vue.config.productionTip = false;

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSignaturePad from 'vue-signature-pad';
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMaterial from 'vue-material';
Vue.use(VueMaterial);

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  Vue,
  dsn: "https://5032b65e305c401580a98d8c9284f877@o509498.ingest.sentry.io/5604053",
  release: `op-forms@${env.VERSION}`,
  environment: env.ENVIRONMENT_NAME,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});


const partialTeam = window.location;
console.log(partialTeam)
// Sentry.setContext('team', partialTeam);

import { ValidationProvider, ValidationObserver } from 'vee-validate';


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver)

import Vuex from 'vuex';

Vue.use(Vuex)

import store from './store';
import VueRouter from 'vue-router';
import OPForm from './components/OPForm.vue';
import Login from './components/Login.vue';


Vue.use(VueRouter);
Vue.config.productionTip = false;
const routes = [
  {
    path: '/preview',
    name: 'preview',
    component: OPForm
  },
  {
    path: '/:partialGroupID',
    component: { template: '' }
  },
  {
    path: '/:partialGroupID/complete/:formID',
    component: OPForm
  },
  {
    path: '/:partialGroupID/login',
    component: Login
  }
];
const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.use(VueSignaturePad);



const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.use(require('vue-moment'));

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn || to.path === '/preview') {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
