import axios from 'axios';
import Vue from 'vue';

const state = () => ({
    status: '',
    user: JSON.parse(localStorage.getItem('user') || '{}'),
    isLoggedIn: !!(JSON.parse(localStorage.getItem('user') || '{}').username),
    refreshToken: null,
    isRobot: true,
    firstName: '',
    lastName: '',
    dob: ''
})

const getters = {
    isLoggedIn: state => state.isLoggedIn,
    authStatus: state => state.status,
    username: state => state.user.username,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    dob: state => state.dob,
    isRobot: state => state.isRobot
}

const actions = {
    async login({ commit, rootState, dispatch }, { firstName, lastName, password }) {
        try {
            const data = await dispatch('http/post', {
                path: `/api/auth/login`,
                body: {
                    username: `${ firstName } ${ lastName }`,
                    firstName,
                    lastName,
                    password,
                    partialGroupID: rootState.group.partialGroupID
                }
            }, { root: true })
            if (data && data.user) {
                await commit('http/set_authentication_header', { token: data.access_token }, { root: true })
                await commit('setRefreshToken', { refreshToken: data.refresh_token });
                await commit('setCurrentUser', { firstName, lastName, dob: password })

                const user = data.user || 'me!'

                localStorage.setItem('user', JSON.stringify(user));

                await commit('auth_success', { user })
                await dispatch('forms/getForms');
                dispatch('group/getLogoUrl');
            }
        } catch (e) {
            commit('auth_error');
            throw e
        }
    },
    async refresh({ commit, dispatch, state }, { }) {
        try {
            if (!state.refreshToken) {
                commit('logout');
                return;
            }
            const data = await dispatch('http/post', { path: `/api/auth/refresh`, body: { refreshToken: state.refreshToken } }, { root: true });
            if (data) {
                await commit('http/set_authentication_header', { token: data.access_token }, { root: true })
                await commit('setRefreshToken', { refreshToken: data.refresh_token })

                const user = data.user;

                localStorage.setItem('user', JSON.stringify(user));

                await commit('auth_success', { user });
                await dispatch('forms/getForms');
                dispatch('group/getLogoUrl');
            }

        } catch (e) {
            commit('logout');
        }
    }
}

const mutations = {
    auth_request(state) {
        state.status = 'loading'
        state.isLoggedIn = false;
    },
    auth_success(state, { user }) {
        state.status = 'success'
        state.user = user
        state.isLoggedIn = true;
    },
    auth_error(state) {
        state.status = 'error'
        state.isLoggedIn = false;
    },
    logout(state) {
        state.isLoggedIn = false;
        console.log('logging out')
        state.status = ''
        localStorage.setItem('token', '')
        localStorage.setItem('user', '{}')
    },
    setRefreshToken(state, { refreshToken }) {
        state.refreshToken = refreshToken;
    },
    setIsRobotFalse(state) {
        state.isRobot = false;
    },
    setCurrentUser(state, { firstName, lastName, dob }) {
        state.firstName = firstName;
        state.lastName = lastName;
        state.dob = dob;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}