<template lang="pug">
  #app
    link(rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons")
    Login(v-show="!isLoggedIn && !isPreviewMode")
    div(v-if="isPreviewMode")
      router-view
    div(v-else)
      div(v-if="isLoggedIn")
        Portal
        router-view
</template>

<script>
import Portal from './components/Portal.vue';
import Login from './components/Login.vue';
import OPForm from './components/OPForm.vue';
import { mapGetters } from 'vuex'
import VueHelmet from 'vue-helmet'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

console.log('Datepicker', Datepicker)
export default {
  name: 'App',
  components: { VueHelmet, Portal, Login, Datepicker },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('forms', [
      'isPreviewMode',
    ]),
    ...mapGetters('group', [
      'partialGroupID',
    ])
  },
  mounted() {
    console.log(process.env); //OP_FORMS_BE_HOST
  },
  async created() {
    this.$material.locale.dateFormat = 'MM/dd/yyyy'
    const { partialGroupID } = this.$route.params;
    // if (!partialGroupID) {
    //   console.error('partialGroupID is required')
    //   return
    // } 
    await this.$store.commit('group/setPartialGroupID', { partialGroupID })
    this.$http.interceptors.response.use(undefined, (err) => {
      console.log('an error occurred', this.isPreviewMode, this.$route, err.status)
      return new Promise((resolve, reject) => {

        this.$store.commit('forms/setPreviewMode', { isOn: this.$route.path === '/preview' });
        if (this.isPreviewMode) {
          return;
        }

        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.commit('session/logout');
        }
        throw err;
      });
    });

    window.onloadCallback = () => {
      window.grecaptcha.render('html_element', {
        'sitekey': '6LfQhPEZAAAAAJci7L98EonyVK-2HZ7KKM0ue5aa',
        'callback': () => {
          // this.isRobot = false
          this.$store.commit('session/setIsRobotFalse');
        }
      });
    };

    this.$store.commit('forms/setPreviewMode', { isOn: this.$route.path === '/preview' });
  }
}
</script>
<style>
html {
    -webkit-print-color-adjust: exact;
}
body {
  background: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background: #f8f8f8; */
}
</style>
